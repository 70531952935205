<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="名称" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="日期" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="查看" placement="top">
						<el-button type="primary" icon="el-icon-view" size="mini" circle @click="handleView(scope.row)"></el-button>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="导出" placement="top">
						<el-button type="warning" icon="el-icon-download" size="mini" circle @click="downloadExcel(scope.row)"></el-button>
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import dayjs from 'dayjs'
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'wj',
				autoRequest: false,
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
			}
		},
		mounted() {
			this.getList()
		},
		methods:{
			getList() {
			  this.loading = true
			  this.axios.get(`/manage/wj/list`, {
			    params: this.queryForm
			  }).then(res => {
			    this.total = parseInt(res.total)
			    this.list = res.data
			    this.loading = false
			  }).catch(err => {
			    this.loading = false
			  })
			},
			handleView(row) {
				console.log(row)
				this.$router.push({
				  path:"/statistics_view",
				  query: {
					id: row.id,
				  }
				})
			},
			downloadExcel(row){
				this.axios.get('/manage/wj/downloadExcel',{
					responseType: 'arraybuffer',
					params:{
						id: row.id,
					}
				}).then(res=>{
					console.log(res)
					  let dayjsTime = dayjs(`${new Date()}`).format('YYYY-MM-DD')
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-word" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = dayjsTime+'.docx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
		}
	}
</script>

<style>
</style>